import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

// STYLES
import { formModalStyles } from 'styles/mui/common/modal-styles';

// HOOKS & API
import useToggle from 'customHooks/useToggle';
import { useUpdateVisaDocumentMutation } from 'services/private/visa';

// COMPONENTS & UTILS
import ModalHeader from 'containers/common/components/ModalHeader';
import ActionsMenu from 'containers/portal/hr/Jobs/components/ActionsMenu';
import VisaRemarksForm from './VisaRemarksForm';
import { VISA_STATUSES } from '../utilities/data';

function VisaDocStatusPicker({ status, itemID }) {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [remarksOpen, toggleRemarksOpen] = useToggle();

  const [updateVisaDocStatus] = useUpdateVisaDocumentMutation();

  const isOpen = Boolean(anchorEl);

  const handleItemClick = async item => {
    setAnchorEl(null);
    if (item?.value === 'rejected') {
      toggleRemarksOpen();
      return;
    }
    const resp = await updateVisaDocStatus({ id: itemID, document_status: item.value });
    if (resp?.data) {
      enqueueSnackbar('Status Approved Successfully', { variant: 'success' });
    }
  };

  const handleRemarkSubmit = async item => {
    const resp = await updateVisaDocStatus({ id: itemID, document_remarks: item.remarks, document_status: 'rejected' });
    if (resp?.data) {
      enqueueSnackbar('Status Rejected Successfully', { variant: 'success' });
    }
  };

  return (
    <>
      <Button
        disabled={status === 'approved'}
        onClick={e => setAnchorEl(e.currentTarget)}
        endIcon={isOpen ? <ExpandLess /> : <ExpandMore />}
        size="small"
        sx={{ bgcolor: theme => theme.palette.action.hover, fontSize: '12px', fontWeight: 500 }}
      >
        {status}
      </Button>

      <ActionsMenu
        disabled={status === 'approved'}
        targetEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onItemClick={item => handleItemClick(item)}
        menuItems={VISA_STATUSES}
        isItemSelected={item => item.value === status}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
      {/* Task revision remarks */}
      <Modal open={remarksOpen} onClose={toggleRemarksOpen}>
        <Box sx={formModalStyles}>
          <ModalHeader title="Visa Document Remarks" onClose={toggleRemarksOpen} />
          <VisaRemarksForm onSubmit={handleRemarkSubmit} />
        </Box>
      </Modal>
    </>
  );
}

VisaDocStatusPicker.defaultProps = {
  itemID: null,
  status: '',
};

VisaDocStatusPicker.propTypes = {
  status: PropTypes.string,
  itemID: PropTypes.number,
};

export default VisaDocStatusPicker;
