import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useDispatch } from 'react-redux';

// API HOOKS
import { useGetForgetCheckOutQuery } from 'services/private/users';
import { useLazyAuthorizedQuery } from 'services/private/auth';
import { useLazyGetBrandingDataQuery } from 'services/private/branding';
import useBuildTheme from 'customHooks/useBuildTheme';

// REDUX ACTIONS
import { onAuthorized, onLoggedOut } from 'store/slices/authSlice';
import { onBrandingDataLoaded } from 'store/slices/brandingSlice';

// COMPONENTS & UTILS
import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import AppRoutes from './routes';

function App() {
  const dispatch = useDispatch();
  const { theme, buildTheme, buildDefaultTheme } = useBuildTheme();
  const [icon, setIcon] = useState('');
  const [getAsyncAuthorizedUser, { data, isSuccess, isLoading: loadingAuth, isError }] =
    useLazyAuthorizedQuery();
  const { data: checkOutData, isLoading: ForgetCheckOutLoading } = useGetForgetCheckOutQuery();

  const [getAsyncBrandingData, { isLoading: loadingBranding }] = useLazyGetBrandingDataQuery();

  useEffect(() => {
    const getData = async () => {
      await getAsyncAuthorizedUser();
      await getAsyncBrandingData()
        .then(res => {
          if (res?.data) {
            setIcon(res?.data?.fav_icon);
            buildTheme(res?.data);
            dispatch(onBrandingDataLoaded(res.data));
          } else {
            buildDefaultTheme();
          }
        })
        .catch(() => {});
    };

    getData();
  }, []);

  const isLoading = loadingBranding || loadingAuth || !theme || (ForgetCheckOutLoading && checkOutData);

  useEffect(() => {
    if (isSuccess) {
      dispatch(onAuthorized(data));
    } else if (isError) dispatch(onLoggedOut());
  }, [data, loadingAuth, isSuccess]);

  if (isLoading) return <GlobalLoader />;

  return (
    <>
      <Helmet>
        <link rel="icon" href={icon} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} autoHideDuration={2000}>
          <AppRoutes />
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
